import React, { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

function Navbar() {
  const [isNavbarMobile, setIsNavbarMobile] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const handleNavToggle = () => {
    setIsNavbarMobile(!isNavbarMobile);
  };

  const handleDropdownToggle = (e) => {
    if (isNavbarMobile) {
      e.preventDefault();
      setIsDropdownActive(!isDropdownActive);
    }
  };

  const handleScrollTo = (e, hash) => {
    if (document.querySelector(hash)) {
      e.preventDefault();

      if (isNavbarMobile) {
        setIsNavbarMobile(false);
      }

      document.querySelector(hash).scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <header id="header" class="fixed-top d-flex align-items-center">
        <div class="container d-flex align-items-center justify-content-between">
          <div class="logo">
            <Link to="/Home">
              <img src="assets/img/Company.png" alt="" class="img-fluid" />
            </Link>
          </div>
          <nav id="navbar" class={isNavbarMobile ? 'navbar-mobile' :"navbar"}>
            <ul>
              <li>
                <Link class="nav-link scrollto active" to="/Home">
                  Home
                </Link>
              </li>
              <li class="dropdown">
                <Link to="/Services">
                  <span>Services</span> <i class="bi bi-chevron-down"></i>
                </Link>
                <ul>
                  <li class="dropdown">
                    <Link to="#">
                      <span>Mobile App Development</span>{" "}
                      <i class="bi bi-chevron-right"></i>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/Iosappdevelopment">Ios App Development</Link>
                      </li>
                      <li>
                        <Link to="/Androidappdevelopment">
                          Android App Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/Hybridappdevelopment">
                          Hybrid/Cross-Platform
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <Link to="/Webappdev">
                      <span>Web Development</span>{" "}
                      <i class="bi bi-chevron-right"></i>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/Webappdev">Web Application Development</Link>
                      </li>
                      <li>
                        <Link to="/Ecommercedev">eCommerce Development</Link>
                      </li>
                      <li>
                        <Link to="/Webandcmsdev">Web and Cms Development</Link>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <Link to="#">
                      <span>Software Development</span>{" "}
                      <i class="bi bi-chevron-right"></i>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/Customersoftwaredev">
                          Custom Software Development
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="dropdown">
                <Link to="/HireDevelopers">
                  <span>Hire Developers</span>{" "}
                  <i class="bi bi-chevron-down"></i>
                </Link>
                <ul>
                  <li>
                    <Link to="/Hirenodejsdev">Hire Nodejs Developers</Link>
                  </li>
                  <li>
                    <Link to="/Hirereactjsdev">Hire React Js Developers</Link>
                  </li>
                  <li>
                    <Link to="/Hirecrossplatformdev">
                      Hire Cross Platform Developers
                    </Link>
                  </li>
                  <li>
                    <Link to="/Hirejavadev">Hire Java Developers</Link>
                  </li>
                  <li>
                    <Link to="/Hireaspdotnetdev">
                      Hire ASP.NET Core Developers
                    </Link>
                  </li>
                  <li>
                    <Link to="/Hireecommercedev">
                      Hire Ecommarce Developers
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link class="nav-link scrollto" to="/Portfolioin">
                  Portfolio
                </Link>
              </li>
              <li>
                <Link class="nav-link scrollto" to="/Contact">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link class="nav-link scrollto" to="/Aboutus">
                  About Us
                </Link>
              </li>
              {/* <li><Link class="nav-link scrollto" to="/Grid">Grid</Link></li> */}
            </ul>
            <i
        className={`bi ${isNavbarMobile ? 'bi-x' : 'bi-list'} mobile-nav-toggle`}
        onClick={handleNavToggle}
      ></i>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Navbar;
